import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { CircleSpinner } from "react-spinners-kit";

import { unsavedChangesSelector, IDispatch, resetAllEdits } from './state';
import { IParams } from './App';
import { saveTranslations, IState } from './state';
import SaveResultBanner from './SaveResultBanner';

const Button = styled.button`
    padding: 0.25rem 0.6rem;
    font-size: 1.2rem;
`;

interface IProps {
    showReset?: boolean;
}

function SaveButton({ showReset }: IProps) {
    const { version } = useParams<IParams>();
    const dispatch = useDispatch<IDispatch>();
    const unsavedChanges = useSelector((state: IState) => unsavedChangesSelector(state, version!));
    const saveStatus = useSelector((state: IState) => state.edits[version!]?.saveStatus ?? '');

    const onSave = () => {
        if (!version) {
            return;
        }
        dispatch(saveTranslations(version));
    };

    const onReset = () => {
        if (!version) {
            return;
        }
        if (!window.confirm('This will reset ALL unsaved changes.')) {
            return;
        }
        dispatch(resetAllEdits(version));
    };

    let portal = null
    if (saveStatus === 'fulfilled' || saveStatus === 'rejected') {
        portal = createPortal(
            <SaveResultBanner success={saveStatus === 'fulfilled'} version={version!} />,
            document.body
        );
    }

    const disabled = saveStatus === 'pending' || !unsavedChanges;

    const changesText = unsavedChanges === 0
        ? 'No unsaved changes'
        : unsavedChanges === 1
            ? '1 unsaved change'
            : `${unsavedChanges} unsaved changes`

    return <>
        <Button disabled={disabled} type='button' onClick={onSave}>
            Save
        </Button>
        {showReset && <Button type='button' onClick={onReset} disabled={disabled}>
            Reset all
        </Button>}
        {saveStatus === 'pending' && <>
            <CircleSpinner size={28} color='#666666' />
            <span>Saving</span>
        </>}
        {saveStatus !== 'pending' && <span>
            {changesText}
        </span>}
        {portal}
    </>;
}

export default SaveButton;
