import * as React from 'react';
import styled from 'styled-components';

interface IProps {
    value: string;
    onChange: React.FormEventHandler<HTMLTextAreaElement>;
}

interface IStyledArea {
    $height: string;
}

const StyleArea = styled.textarea<IStyledArea>`
    height: ${(({ $height }) => $height)};
    z-index: 1;
    border: none;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
`;

function Textarea({ value, onChange }: IProps) {
    const [height, setHeight] = React.useState('auto');
    const ref = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        setHeight(`${ref.current!.scrollHeight}px`);
    }, [value]);

    const handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        setHeight('auto');
        onChange(event);
    }

    return <StyleArea value={value} onChange={handleChange} ref={ref} $height={height} rows={1} />
}

export default Textarea;
