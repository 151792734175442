import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {IState, docsItemSelector, editContent, IDispatch, resetEdit} from '../state';
import {LANGUAGES, ILanguage, REFERENCE_LANGUAGE} from '../types';
import {ContentRow, Item, IDHeading} from '../stylings';
import DocItem from './DocItem';
import Modal from '../Modal';
import AiAssist from './AiAssist';

interface IProps {
    id: string;
    version: string;
}

function DocRow({id, version}: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const texts = useSelector((state: IState) => docsItemSelector(state, version, id));
    const langs = useSelector((state: IState) => state.languages);
    const [modalContent, setModalContent] = React.useState<{text: string, lang: ILanguage}|null>(null);

    const changeHandler = React.useCallback((event: React.FormEvent<HTMLTextAreaElement>, lang: ILanguage) => {
        dispatch(editContent({version, lang, id, content: 'docs', value: event.currentTarget.value}));
    }, []);

    const resetHandler = React.useCallback((lang: ILanguage) => {
        if (!window.confirm('This will throw away your changes reset it back to the latest saved version.')) {
            return;
        }
        dispatch(resetEdit({version, lang, id, content: 'docs'}));
    }, []);

    const closeAi = React.useCallback(() => {
        setModalContent(null);
    }, []);

    const setAiModal = React.useCallback((lang: ILanguage) => {
        setModalContent({lang, text: texts[REFERENCE_LANGUAGE]});
    }, [texts]);

    return <Item>
        <IDHeading>ID: {id}</IDHeading>
        <ContentRow>
            {LANGUAGES
                .filter(lang => langs[lang])
                .map(lang => <DocItem
                    key={lang}
                    lang={lang}
                    text={texts[lang]}
                    resetHandler={resetHandler}
                    changeHandler={changeHandler}
                    id={id}
                    version={version}
                    setAiModal={setAiModal}
                />)
            }
        </ContentRow>
        <Modal
            isOpen={!!modalContent}
            onRequestClose={closeAi}
            contentLabel="AI Assist"
        >
            <AiAssist
                close={closeAi}
                version={version}
                id={id}
                text={modalContent?.text ?? ''}
                lang={modalContent?.lang ?? 'en'}
                content='docs'
            />
        </Modal>
    </Item>;
}

export default React.memo(DocRow);