import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import styled from 'styled-components';

import VersionPicker from './VersionPicker';
import { IDispatch, loadVersions, anyUnsavedChangesSelector } from './state';
import Main from './Main';
import SaveButton from './SaveButton';
import ScrollToTop from './ScrollToTop';

export type IParams = 'version';

const Wrapper = styled.div`
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
`;

const Header = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
`;

const Footer = styled.footer`
    border-top: 1px solid #ccc;
    padding: 10px 0;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
    margin-bottom: 3rem;

    img {
        width: 320px;
        object-fit: contain;
        height: 1.5rem;
    }
`;

function App() {
    const dispatch = useDispatch<IDispatch>();
    const anyUnsaved = useSelector(anyUnsavedChangesSelector);
    useBeforeunload(() => {
        if (anyUnsaved) {
            return 'You have unsaved changes'
        } else {
            return;
        }
    });
    React.useEffect(() => {
        dispatch(loadVersions());
    }, []);
    return <Wrapper>
        <Header>
            <h1>Translator</h1>
            <VersionPicker />
            <Routes>
                <Route path='/:version/*' element={<SaveButton showReset />} />
            </Routes>
        </Header>
        <main>
            <Routes>
                <Route path='/:version/*' element={<>
                    <ScrollToTop />
                    <Main />
                </>} />
            </Routes>
        </main>
        <Footer>
            <img alt='logo' src='/static/TalentMiles-logo-liggande.png' width='1800' height='363' />
            <div>© TalentMiles</div>
        </Footer>
    </Wrapper>;
}

export default App;