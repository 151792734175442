import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IState, defaultContentPageSelector } from '../state';
import { IPageParams } from '../Main';
import { getInt } from '../utils';
import PageNav from '../PageNav';
import DefaultContentRow from './DefaultContentRow';
import LangHeading from '../LangHeading';
import Counts from './Counts';
import DefaultContentFormatting from './DefaultContentFormatting';
import ModuleSelector from '../ModuleSelector';

function DefaultContent() {
    const { version, page } = useParams<IPageParams>();
    const pageNr = getInt(page!) ?? 1;
    const { ids, pageCount } = useSelector((state: IState) => defaultContentPageSelector(state, version!, pageNr));

    return <>
        <ModuleSelector content={'defaultContent'} version={version!} />
        <DefaultContentFormatting />
        <Counts version={version!} content='defaultContent' />
        <PageNav page={pageNr} maxPage={pageCount} version={version!} content='default-content' />
        <LangHeading />
        <div>
            {ids.map(id =>
                <DefaultContentRow
                    key={id}
                    id={id}
                    version={version!}
                />
            )}
        </div>
        <PageNav page={pageNr} maxPage={pageCount} version={version!} content='default-content' />
    </>;
}

export default React.memo(DefaultContent);
