import * as React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {IState} from './state';
import {LANGUAGES, LANGUAGE_NAMES} from './types';
import {ContentRow} from './stylings'

const H3 = styled.h3`
    margin-bottom: 0.5rem;
`;

function LangHeading() {
    const langs = useSelector((state: IState) => state.languages);
    return <ContentRow>
        {LANGUAGES
            .filter(lang => !!langs[lang])
            .map(lang => <H3 key={lang}>{LANGUAGE_NAMES[lang]}</H3>)}
    </ContentRow>
}

export default React.memo(LangHeading);
