import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {IState, IDispatch, updateSearchFilter} from '../state';

interface IProps {
    version: string;
}

function SearchFilter({version}: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const value = useSelector((state: IState) => state.filters[version]?.searchFilter ?? '');

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        dispatch(updateSearchFilter({version, value}));
    }
    return <label>
        Content: 
        <input type='text' value={value} onChange={onChange} />
    </label>
}

export default SearchFilter;