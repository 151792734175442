import * as React from 'react';
import { useParams, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircleSpinner } from "react-spinners-kit";
import styled from "styled-components";

import { IParams } from './App';
import { IDispatch, loadTranslations, IState, loadStatusSelector } from './state';
import Error from './Error';
import NavBar from './NavBar';
import Texts from './editors/Texts';
import Docs from './editors/Docs';
import DefaultContent from './editors/DefaultContent';
import LangSelector from './LangSelector';
import Search from './search/Search';
import { Row } from './stylings'
import SaveButton from './SaveButton';

export type IPageParams = IParams | 'page';

const SaveRow = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: white;
    padding: 5px 15px;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    gap: 1rem;
`;

function Main() {
    const dispatch = useDispatch<IDispatch>();
    const { version } = useParams<IParams>();
    const loadStatus = useSelector((state: IState) => loadStatusSelector(state, version!));

    React.useEffect(() => {
        dispatch(loadTranslations(version!));
    }, [version]);

    if (loadStatus === 'pending') {
        return <CircleSpinner size={48} color='#666666' />;
    } else if (loadStatus === 'rejected') {
        return <Error />;
    }
    return <>
        <Row>
            <Search />
            <LangSelector />
        </Row>
        <NavBar version={version!} />
        <Routes>
            <Route path={`texts/:page`} element={<Texts />} />
            <Route path={`texts`} element={<Navigate to={`/${version}/texts/1`} />} />
            <Route path={`docs/:page`} element={<Docs />} />
            <Route path={`docs`} element={<Navigate to={`/${version}/docs/1`} />} />
            <Route path={`default-content/:page`} element={<DefaultContent />} />
            <Route path={`default-content`} element={<Navigate to={`/${version}/default-content/1`} />} />
            <Route path={`/`} element={<Navigate to={`/${version}/texts/1`} />} />
        </Routes>
        <SaveRow>
            <SaveButton />
        </SaveRow>
    </>;
}

export default Main;