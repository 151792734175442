import { createSelector } from '@reduxjs/toolkit';

import { IState } from './index';
import { ILanguage, LANGUAGES, PAGE_SIZE } from '../types';
import { onlyUnique } from '../utils';

const translationSelector = (state: IState, version: string) => state.translations[version];
const editsSelector = (state: IState, version: string) => state.edits[version];

export const loadStatusSelector = createSelector(
    translationSelector,
    (translationState) => {
        return translationState?.loadStatus ?? 'pending';
    }
);

export const textModulesSelector = createSelector(
    translationSelector,
    (translations) => {
        const modules = Object.keys(translations.data.texts.dict).map(id => id.split('.')[0]);
        return modules.filter(onlyUnique);
    }
);

export const docsModulesSelector = createSelector(
    translationSelector,
    (translations) => {
        const modules = Object.keys(translations.data.docs.dict).map(id => id.split('.')[0]);
        return modules.filter(onlyUnique);
    }
);

export const defaultContentModulesSelector = createSelector(
    translationSelector,
    (translations) => {
        const modules = Object.keys(translations.data.defaultContent.dict).map(id => id.split('.')[0]);
        return modules.filter(onlyUnique);
    }
);

const textsIdsSelector = createSelector(
    (state: IState, version: string) => translationSelector(state, version),
    (state: IState, version: string) => state.filters[version]?.searchResults,
    textModulesSelector,
    (state: IState, version: string) => state.modules,
    (translation, searchResults, modules, disabledModules) => {
        const ids = searchResults?.texts ?? translation.data.texts.ids;
        const filteredModules = modules.filter(mod => !disabledModules[mod]);
        return ids.filter(id => filteredModules.includes(id.split('.')[0.]));
    }
);

const docsIdsSelector = createSelector(
    (state: IState, version: string) => translationSelector(state, version),
    (state: IState, version: string) => state.filters[version]?.searchResults,
    docsModulesSelector,
    (state: IState, version: string) => state.modules,
    (translation, searchResults, modules, disabledModules) => {
        const ids = searchResults?.docs ?? translation.data.docs.ids;
        const filteredModules = modules.filter(mod => !disabledModules[mod]);
        return ids.filter(id => filteredModules.includes(id.split('.')[0.]));
    }
);

const defaultContentIdsSelector = createSelector(
    (state: IState, version: string) => translationSelector(state, version),
    (state: IState, version: string) => state.filters[version]?.searchResults,
    defaultContentModulesSelector,
    (state: IState, version: string) => state.modules,
    (translation, searchResults, modules, disabledModules) => {
        const ids = searchResults?.defaultContent ?? translation.data.defaultContent.ids;
        const filteredModules = modules.filter(mod => !disabledModules[mod]);
        return ids.filter(id => filteredModules.includes(id.split('.')[0.]));
    }
);

export const textCountsSelector = createSelector(
    textsIdsSelector,
    (state: IState, version: string) => translationSelector(state, version),
    (filterIds, translation) => {
        return [filterIds.length, translation.data.texts.ids.length];
    }
);

export const docCountsSelector = createSelector(
    docsIdsSelector,
    (state: IState, version: string) => translationSelector(state, version),
    (filterIds, translation) => {
        return [filterIds.length, translation.data.docs.ids.length];
    }
);

export const defaultContentCountsSelector = createSelector(
    defaultContentIdsSelector,
    (state: IState, version: string) => translationSelector(state, version),
    (filterIds, translation) => {
        return [filterIds.length, translation.data.defaultContent.ids.length];
    }
);

export const textsPageSelector = createSelector(
    textsIdsSelector,
    (state: IState, version: string, page: number) => page,
    (ids, page) => {
        return {
            pageCount: Math.ceil(ids.length / PAGE_SIZE),
            ids: ids.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
        };
    }
);

export const docsPageSelector = createSelector(
    docsIdsSelector,
    (state: IState, version: string, page: number) => page,
    (ids, page) => {
        return {
            pageCount: Math.ceil(ids.length / PAGE_SIZE),
            ids: ids.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
        };
    }
);

export const defaultContentPageSelector = createSelector(
    defaultContentIdsSelector,
    (state: IState, version: string, page: number) => page,
    (ids, page) => {
        return {
            pageCount: Math.ceil(ids.length / PAGE_SIZE),
            ids: ids.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
        };
    }
);

const editTextsSelector = createSelector(
    (state: IState, version: string, id: string) => editsSelector(state, version).data,
    (state: IState, version: string, id: string) => id,
    (edits, id) => {
        if (!edits.texts) {
            return;
        }
        if (!edits.texts[id]) {
            return;
        }
        return edits.texts[id];
    }
);

const editDocsSelector = createSelector(
    (state: IState, version: string, id: string) => editsSelector(state, version).data,
    (state: IState, version: string, id: string) => id,
    (edits, id) => {
        if (!edits.docs) {
            return;
        }
        if (!edits.docs[id]) {
            return;
        }
        return edits.docs[id];
    }
);

const editDefaultContentSelector = createSelector(
    (state: IState, version: string, id: string) => editsSelector(state, version).data,
    (state: IState, version: string, id: string) => id,
    (edits, id) => {
        if (!edits.defaultContent) {
            return;
        }
        if (!edits.defaultContent[id]) {
            return;
        }
        return edits.defaultContent[id];
    }
);

export const textsItemSelector = createSelector(
    (state: IState, version: string, id: string) => translationSelector(state, version).data,
    (state: IState, version: string, id: string) => editTextsSelector(state, version, id),
    (state: IState, version: string, id: string) => id,
    (data, edits, id) => {
        return LANGUAGES.reduce((obj, lang) => {
            obj[lang] = (edits ?? {})[lang] ?? (data.texts.dict[id] ?? {})[lang];
            return obj;
        }, {} as { [key in ILanguage]: string });
    }
);

export const docsItemSelector = createSelector(
    (state: IState, version: string, id: string) => translationSelector(state, version).data,
    (state: IState, version: string, id: string) => editDocsSelector(state, version, id),
    (state: IState, version: string, id: string) => id,
    (data, edits, id) => {
        return LANGUAGES.reduce((obj, lang) => {
            obj[lang] = (edits ?? {})[lang] ?? (data.docs.dict[id] ?? {})[lang];
            return obj;
        }, {} as { [key in ILanguage]: string });
    }
);
export const defaultContentItemSelector = createSelector(
    (state: IState, version: string, id: string) => translationSelector(state, version).data,
    (state: IState, version: string, id: string) => editDefaultContentSelector(state, version, id),
    (state: IState, version: string, id: string) => id,
    (data, edits, id) => {
        return LANGUAGES.reduce((obj, lang) => {
            obj[lang] = (edits ?? {})[lang] ?? (data.defaultContent.dict[id] ?? {})[lang];
            return obj;
        }, {} as { [key in ILanguage]: string });
    }
);

export const unsavedChangesSelector = createSelector(
    (state: IState) => state.edits,
    (state: IState, version: string) => version,
    (edits, version) => {
        if (!edits[version]) {
            return 0;
        }
        const numEdits = Object.values(edits[version].data)
            .map(cat => Object.keys(cat).length)
            .reduce((acc, val) => acc + val, 0);
        return numEdits;
    }
);

export const anyUnsavedChangesSelector = createSelector(
    (state: IState) => state.edits,
    (edits) => {
        for (const version of Object.values(edits)) {
            if (Object.keys(version.data).length > 0) {
                return true;
            }
        }
        return false;
    }
);

export const hasSearchResults = createSelector(
    (state: IState, version: string) => state.filters[version],
    (filter) => {
        return !!(filter?.searchResults);
    }
);
