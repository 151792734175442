import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as API from '../api';

export const loadVersions = createAsyncThunk(
    'versions/load',
    async () => {
        const response = await API.getVersions();
        return response;
    }
);

interface IVersionState {
    versions: string[];
}

const initialState: IVersionState = {
    versions: [],
};

const versionsSlice = createSlice({
    name: 'versions',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadVersions.fulfilled, (state, action) => {
            state.versions = action.payload.reverse();
        })
    }
});

// export const {  } = versionsSlice.actions
export default versionsSlice.reducer;