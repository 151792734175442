import * as React from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircleSpinner } from "react-spinners-kit";

import { IState } from './state';

function VersionPicker() {
    const navigate = useNavigate();
    const match = useMatch('/:version/*');
    const versions = useSelector((state: IState) => state.versions.versions);

    const changeVersion = (event: React.FormEvent<HTMLSelectElement>) => {
        navigate(`/${event.currentTarget.value}`);
    }

    if (versions.length === 0) {
        return <CircleSpinner size={28} color='#666666' />;
    }

    return <select onChange={changeVersion} value={match?.params.version}>
        <option value='' key=''>Select version</option>
        {versions.map(v => <option value={v} key={v}>{v}</option>)}
    </select>;
}

export default VersionPicker;