import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {IContent, ILanguage} from '../types';
import {IPartialLangDict, saveTranslations, loadTranslations} from './translations';

interface IEdits {
    saveStatus: 'pending'|'rejected'|'fulfilled'|'',
    data: {
        [P in IContent]?: IPartialLangDict
    },
}

interface ITranslationsState {
    [index: string]: IEdits;
}

const initialState: ITranslationsState = {};

function newVersionState(): IEdits {
    return {
        saveStatus: '',
        data: {},
    }
}


interface IEditPayload {
    version: string;
    content: IContent;
    lang: ILanguage;
    id: string;
    value: string;
}

interface IResetPayload {
    version: string;
    content: IContent;
    lang: ILanguage;
    id: string;
}

const editsSlice = createSlice({
    name: 'edits',
    initialState,
    reducers: {
        editContent(state, action: PayloadAction<IEditPayload>) {
            const {version, content, lang, id, value} = action.payload;
            if (state[version] === undefined) {
                return;
            }
            if (state[version].data[content] === undefined) {
                state[version].data[content] = {};
            }
            if (state[version].data[content]![id] === undefined) {
                state[version].data[content]![id] = {};
            }
            state[version].data[content]![id][lang] = value;
        },
        clearSaveStatus(state, action: PayloadAction<string>) {
            const version = action.payload;
            state[version].saveStatus = '';
        },
        resetEdit(state, action: PayloadAction<IResetPayload>) {
            const {version, content, lang, id} = action.payload;
            if (state[version] === undefined) {
                return;
            }
            if (state[version].data[content] === undefined) {
                return;
            }
            if (state[version].data[content]![id] === undefined) {
                return;
            }
            delete state[version].data[content]![id][lang];
            if (!Object.values(state[version].data[content]![id]).some(v => !!v)) {
                delete state[version].data[content]![id];
            }
            if (!Object.values(state[version].data[content]!).some(v => !!v)) {
                delete state[version].data[content];
            }
        },
        resetAllEdits(state, action: PayloadAction<string>) {
            const version = action.payload;
            state[version].data = {};
        }
    },
    extraReducers: builder => {
        builder.addCase(loadTranslations.fulfilled, (state, action) => {
            const version = action.meta.arg;
            if (!state[version]) {
                state[version] = newVersionState();
            }
        });
        builder.addCase(saveTranslations.pending, (state, action) => {
            const verison = action.meta.arg;
            state[verison].saveStatus = 'pending';
        });
        builder.addCase(saveTranslations.rejected, (state, action) => {
            const verison = action.meta.arg;
            state[verison].saveStatus = 'rejected';
        });
        builder.addCase(saveTranslations.fulfilled, (state, action) => {
            const verison = action.meta.arg;
            const payload = action.payload;
            state[verison].saveStatus = 'fulfilled';
            state[verison].data = {};
        });
    }
});

export const { editContent, clearSaveStatus, resetEdit, resetAllEdits } = editsSlice.actions
export default editsSlice.reducer;