import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as API from '../api';
import { LANGUAGES, REFERENCE_LANGUAGE, IContent, ILanguage } from '../types';
import { IState } from './index';
import { aiApi } from './ai';

export const loadTranslations = createAsyncThunk(
    'translations/load',
    async (version: string) => {
        const response = await API.getTranslations(version);
        return response;
    }
);

export const saveTranslations = createAsyncThunk(
    'translations/save',
    async (version: string, thunkAPI) => {
        const state: IState = thunkAPI.getState() as IState;
        const data = revertData(state.edits[version].data);
        const response = await API.saveTranslations({ data, version });
        thunkAPI.dispatch(aiApi.util.invalidateTags(['aiTranslation']));
        return response;
    }
);

export type ILangData = {
    [key in ILanguage]: string;
}

interface ILangDict {
    [index: string]: ILangData;
}

export interface IPartialLangDict {
    [index: string]: Partial<ILangData>;
}

interface ITranslation {
    loadStatus: 'pending' | 'rejected' | 'fulfilled',
    data: {
        texts: {
            ids: string[],
            dict: ILangDict,
        },
        docs: {
            ids: string[],
            dict: ILangDict,
        },
        defaultContent: {
            ids: string[],
            dict: ILangDict,
        },
    },
}

interface ITranslationsState {
    [index: string]: ITranslation;
}

const initialState: ITranslationsState = {};

function newVersionState(): ITranslation {
    return {
        loadStatus: 'pending',
        data: {
            texts: {
                ids: [],
                dict: {},
            },
            docs: {
                ids: [],
                dict: {},
            },
            defaultContent: {
                ids: [],
                dict: {},
            },
        },
    }
}

function invertData(data: API.IContent): ILangDict {
    const out: ILangDict = {};
    for (const id of Object.keys(data[REFERENCE_LANGUAGE])) {
        out[id] = LANGUAGES.reduce((obj, lang) => {
            obj[lang] = data[lang][id];
            return obj;
        }, {} as ILangData);
    }
    return out;
}

function revertData(data: {
    [P in IContent]?: IPartialLangDict;
}): API.IData {
    const out: API.IData = {
        texts: LANGUAGES.reduce((obj, lang) => { obj[lang] = {}; return obj }, {} as API.IContent),
        docs: LANGUAGES.reduce((obj, lang) => { obj[lang] = {}; return obj }, {} as API.IContent),
        defaultContent: LANGUAGES.reduce((obj, lang) => { obj[lang] = {}; return obj }, {} as API.IContent),
    };
    for (const [content, dict] of Object.entries(data) as [IContent, IPartialLangDict][]) {
        for (const [id, item] of Object.entries(dict)) {
            for (const [lang, value] of Object.entries(item) as [ILanguage, string][]) {
                out[content][lang][id] = value;
            }
        }
    }
    return out;
}

const translationsSlice = createSlice({
    name: 'translations',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadTranslations.pending, (state, action) => {
            const verison = action.meta.arg;
            if (!state[verison]) {
                state[verison] = newVersionState();
            }
        });
        builder.addCase(loadTranslations.rejected, (state, action) => {
            const verison = action.meta.arg;
            if (state[verison].loadStatus !== 'fulfilled') {
                state[verison].loadStatus = 'rejected';
            }
        });
        builder.addCase(loadTranslations.fulfilled, (state, action) => {
            const verison = action.meta.arg;
            const payload = action.payload;
            state[verison].loadStatus = 'fulfilled';
            state[verison].data.defaultContent.dict = invertData(payload.defaultContent);
            state[verison].data.defaultContent.ids = Object.keys(state[verison].data.defaultContent.dict).sort();
            state[verison].data.texts.dict = invertData(payload.texts);
            state[verison].data.texts.ids = Object.keys(state[verison].data.texts.dict).sort();
            state[verison].data.docs.dict = invertData(payload.docs);
            state[verison].data.docs.ids = Object.keys(state[verison].data.docs.dict).sort();
        });
        builder.addCase(saveTranslations.fulfilled, (state, action) => {
            const verison = action.meta.arg;
            const payload = action.payload;
            state[verison].loadStatus = 'fulfilled';
            state[verison].data.defaultContent.dict = invertData(payload.defaultContent);
            state[verison].data.defaultContent.ids = Object.keys(state[verison].data.defaultContent.dict).sort();
            state[verison].data.texts.dict = invertData(payload.texts);
            state[verison].data.texts.ids = Object.keys(state[verison].data.texts.dict).sort();
            state[verison].data.docs.dict = invertData(payload.docs);
            state[verison].data.docs.ids = Object.keys(state[verison].data.docs.dict).sort();
        });
    }
});

export default translationsSlice.reducer;