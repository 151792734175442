import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IState, docsPageSelector } from '../state';
import { IPageParams } from '../Main';
import { getInt } from '../utils';
import PageNav from '../PageNav';
import DocRow from './DocRow';
import LangHeading from '../LangHeading';
import Counts from './Counts';
import DocFormatting from './DocFormatting';
import ModuleSelector from '../ModuleSelector';

function Docs() {
    const { version, page } = useParams<IPageParams>();
    const pageNr = getInt(page!) ?? 1;
    const { ids, pageCount } = useSelector((state: IState) => docsPageSelector(state, version!, pageNr));

    return <>
        <ModuleSelector content={'docs'} version={version!} />
        <DocFormatting />
        <Counts version={version!} content='docs' />
        <PageNav page={pageNr} maxPage={pageCount} version={version!} content='docs' />
        <LangHeading />
        <div>
            {ids.map(id => <DocRow key={id}
                id={id}
                version={version!}
            />)}
        </div>
        <PageNav page={pageNr} maxPage={pageCount} version={version!} content='docs' />
    </>;
}

export default React.memo(Docs);
