import * as React from 'react';
import { createRoot } from 'react-dom/client';
import {
    BrowserRouter
} from "react-router-dom";
import { Provider } from 'react-redux';
import * as Modal from 'react-modal';

import { store } from './state';
import App from './App';
import GlobalStyle from './GlobalStyle';

// init function
function init() {
    Modal.setAppElement('#app');
    const container = document.getElementById('app');
    const root = createRoot(container!);

    root.render(
        <BrowserRouter>
            <Provider store={store}>
                <App />
                <GlobalStyle />
            </Provider>
        </BrowserRouter>
    );
}

init();