import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IState, textsPageSelector } from '../state';
import { IPageParams } from '../Main';
import { getInt } from '../utils';
import PageNav from '../PageNav';
import TextRow from './TextRow';
import LangHeading from '../LangHeading';
import Counts from './Counts';
import TextFormatting from './TextFormatting';
import ModuleSelector from '../ModuleSelector';

function Texts() {
    const { version, page } = useParams<IPageParams>();
    const pageNr = getInt(page!) ?? 1;
    const { ids, pageCount } = useSelector((state: IState) => textsPageSelector(state, version!, pageNr));

    return <>
        <ModuleSelector content={'texts'} version={version!} />
        <TextFormatting />
        <Counts version={version!} content='texts' />
        <PageNav page={pageNr} maxPage={pageCount} version={version!} content='texts' />
        <LangHeading />
        <div>
            {ids.map(id => <TextRow
                key={id}
                id={id}
                version={version!}
            />)}
        </div>
        <PageNav page={pageNr} maxPage={pageCount} version={version!} content='texts' />
    </>;
}

export default React.memo(Texts);