import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { IState, textCountsSelector, docCountsSelector, defaultContentCountsSelector } from './state';

interface IProps {
    version: string;
}

const StyledNavLink = styled(NavLink)`
    border: 1px solid grey;
    padding: 0.5rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    color: black;
    text-decoration: none;
    &:hover, &:focus, &:active, &:visited {
        color: black;
    }  
    &.active {
        font-weight: bold;
    }
`;

const Nav = styled.nav`
    display: flex;
    gap: 0.5rem;
`;

function NavBar({ version }: IProps) {
    const [textCount, _] = useSelector((state: IState) => textCountsSelector(state, version))
    const [docCount, __] = useSelector((state: IState) => docCountsSelector(state, version))
    const [defaultCount, ___] = useSelector((state: IState) => defaultContentCountsSelector(state, version))
    return <Nav>
        <StyledNavLink to={`../${version}/texts`}>
            Texts ({textCount})
        </StyledNavLink>
        <StyledNavLink to={`../${version}/docs`}>
            Docs ({docCount})
        </StyledNavLink>
        <StyledNavLink to={`../${version}/default-content`}>
            Default content ({defaultCount})
        </StyledNavLink>
    </Nav>;
}

export default NavBar;