import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import {IState} from './index';
import {loadStatusSelector} from './selectors';
import {loadTranslations} from './translations';

export const selectDiffVersion = createAsyncThunk(
    'diff/selectDiffVersion',
    async (version: string, thunkAPI) => {
        let state = thunkAPI.getState() as IState;
        if (version) {
            if (loadStatusSelector(state, version) !== 'fulfilled') {
                await thunkAPI.dispatch((loadTranslations(version)));
            }
        }
        thunkAPI.dispatch(diffSlice.actions.setDiffVersion({version}))
    }
);

type IModulesState = {
    diffVersion: string;
}

const initialState: IModulesState = {
    diffVersion: '',
};

const diffSlice = createSlice({
    name: 'diff',
    initialState,
    reducers: {
        setDiffVersion(state, action: PayloadAction<{version: string}>) {
            const {version} = action.payload;
            state.diffVersion = version;
        }
    },
});

export default diffSlice.reducer;