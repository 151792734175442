export function getInt(from: string): number|null {
    const result = Number.parseInt(from);
    if (Number.isInteger(result)) {
        return result;
    } else {
        return null;
    }
}

export function onlyUnique<T>(value: T, index: number, self: T[]) {
    return self.indexOf(value) === index;
  }