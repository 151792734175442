import * as React from 'react';
import {Parser} from 'commonmark';
import { useDebounce } from 'use-debounce';

import {ILanguage, AI_LANGUAGES} from '../types';
import {ContentBox, ContentError, ContentBorder} from '../stylings';
import Textarea from './Textarea';
import RenderMark from './RenderMark';
import DocsDiff from './DocsDiff';
import EditorButtons from './EditorButtons';

const parser = new Parser();

function validate(value: string): string {
    if (!value) {
        return '';
    }
    try {
        parser.parse(value);
    } catch (err: any) {
        return err.message;
    }
    return '';
}

interface IProps {
    text: string;
    lang: ILanguage;
    id: string;
    version: string;
    resetHandler: (lang: ILanguage) => void;
    changeHandler: (event: React.FormEvent<HTMLTextAreaElement>, lang: ILanguage) => void;
    setAiModal: (lang: ILanguage) => void;
}

type ITab = 'editor'|'preview'|'diff';

function DocItem({text, changeHandler, resetHandler, lang, setAiModal, version, id}: IProps) {
    const [debounceText] = useDebounce(text, 1000);
    const [tab, setTab] = React.useState<ITab>('editor');

    const error = React.useMemo(() => {
        return validate(debounceText);
    }, [debounceText]);

    let mainContent = null;
    if (tab === 'editor') {
        mainContent = <ContentBorder $pad={false}>
            <Textarea value={text} onChange={e => changeHandler(e, lang)} />
            {error && <ContentError>{error}</ContentError>}
        </ContentBorder>
    } else if (tab === 'preview') {
        mainContent = <ContentBorder $pad={true}>
            <RenderMark content={text} />
        </ContentBorder>;
    } else if (tab === 'diff') {
        mainContent = <ContentBorder $pad={true}>
            <DocsDiff version={version} lang={lang} id={id} />
        </ContentBorder>;
    }

    return <ContentBox>
        <EditorButtons
            tab={tab}
            setTab={setTab}
            onReset={() => resetHandler(lang)}
            openAI={() => setAiModal(lang)}
            showAI={AI_LANGUAGES.includes(lang)}
        />
        {mainContent}
    </ContentBox>;
}

export default React.memo(DocItem);