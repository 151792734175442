import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CircleSpinner } from "react-spinners-kit";

import {
    defaultContentItemSelector,
    docsItemSelector,
    editContent,
    IDispatch,
    IState,
    textsItemSelector,
    useAiAssistQuery
} from '../state';
import { ILanguage, IContent, AI_LANGUAGES, REFERENCE_LANGUAGE } from '../types';
import Nl2br from '../Nl2br';

interface IProps {
    close: VoidFunction;
    version: string;
    id: string;
    lang: ILanguage;
    content: IContent;
    text: string;
}

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 1rem;
    gap: 1rem;
`;

const Trans = styled.div`
    padding: 4px;
    border: 1px solid rgb(218, 218, 218);
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: flex-start;
`;

const EMTPY_ARRYAY: [string, string][] = [];

function useExamples(id: string, lang: ILanguage, content: IContent): [string, string][] {
    const diffVersion = useSelector((state: IState) => state.diff.diffVersion);
    if (!diffVersion) {
        return EMTPY_ARRYAY;
    }
    const contents = content === 'texts'
        ? useSelector((state: IState) => textsItemSelector(state, diffVersion, id))
        : content === 'docs'
            ? useSelector((state: IState) => docsItemSelector(state, diffVersion, id))
            : useSelector((state: IState) => defaultContentItemSelector(state, diffVersion, id));

    if (!contents) {
        return EMTPY_ARRYAY;
    }
    const ref_example = contents[REFERENCE_LANGUAGE];
    const trans_example = contents[lang];
    if (!ref_example || !trans_example) {
        return EMTPY_ARRYAY;
    }
    return React.useMemo(() => {
        const example = [ref_example, trans_example] as [string, string];
        return [example];
    }, [ref_example, trans_example]);
}

function AiAssist({ close, version, id, lang, content, text }: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const skip = !AI_LANGUAGES.includes(lang);
    const examples = useExamples(id, lang, content);
    const { data, isError, isLoading } = useAiAssistQuery({ language: lang, text, version, examples }, { skip });

    const onUse = (text: string) => {
        close();
        dispatch(editContent({ version, lang, id, content, value: text }));
    };

    let main = null;
    if (isLoading) {
        main = <CircleSpinner size={48} color='#666666' />;
    } else if (isError) {
        main = <>
            <p>Error</p>
        </>
    } else if (data) {
        main = <>
            {data.translations.map((res, idx) => <Container key={idx}>
                <Trans><Nl2br text={res} /></Trans>
                <div>
                    <button autoFocus type='button' onClick={() => onUse(res)}>Use translation</button>
                </div>
            </Container>)}
        </>;
    }

    return <>
        <h2>Ai Assist</h2>
        <Grid>
            <h3>English text</h3>
            <h3>Suggested translations</h3>
            <Trans><Nl2br text={text} /></Trans>
            <div>
                {main}
            </div>
        </Grid>
    </>;
}

export default React.memo(AiAssist);
