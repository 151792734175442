import * as React from 'react';
import { useDebounce } from 'use-debounce';

import {ILanguage, AI_LANGUAGES} from '../types';
import {ContentBox, ContentError, ContentBorder} from '../stylings';
import Textarea from './Textarea';
import DefaultContentPreview from './DefaultContentPreview';
import DefaultContentDiff from './DefaultContentDiff';
import EditorButtons from './EditorButtons';

interface IProps {
    text: string;
    lang: ILanguage;
    id: string;
    version: string;
    resetHandler: (lang: ILanguage) => void;
    changeHandler: (event: React.FormEvent<HTMLTextAreaElement>, lang: ILanguage) => void;
    setAiModal: (lang: ILanguage) => void;
}

const OPEN_CLOSE = new RegExp('({{)|(}})', 'gm');

function validate(value: string): string {
    if (!value) {
        return '';
    }
    let open = false;
    const matches = value.match(OPEN_CLOSE);
    if (!matches) {
        return '';
    }
    for (const match of matches) {
        if (open && match === '{{') {
            return 'Found invalid nested opening tags {{';
        }
        if (!open && match === '}}') {
            return 'Found invalid closing tag }}';
        }
        if (!open && match === '{{') {
            open = true;
            continue;
        }
        if (open && match === '}}') {
            open = false;
            continue;
        }
    }
    if (open) {
        return 'Missing closing tag }}'
    }
    return '';
}

type ITab = 'editor'|'preview'|'diff';

function DeafultContentItem({text, changeHandler, resetHandler, lang, setAiModal, version, id}: IProps) {
    const [debounceText] = useDebounce(text, 1000);
    const [tab, setTab] = React.useState<ITab>('editor');

    const error = React.useMemo(() => {
        return validate(debounceText);
    }, [debounceText]);

    let mainContent = null;
    if (tab === 'editor') {
        mainContent = <ContentBorder $pad={false}>
            <Textarea value={text} onChange={e => changeHandler(e, lang)} />
            {error && <ContentError>{error}</ContentError>}
        </ContentBorder>
    } else if (tab === 'preview') {
        mainContent = <ContentBorder $pad={true}>
            <DefaultContentPreview text={text} />
        </ContentBorder>;
    } else if (tab === 'diff') {
        mainContent = <ContentBorder $pad={true}>
            <DefaultContentDiff version={version} lang={lang} id={id} />
        </ContentBorder>;
    }

    return <ContentBox>
        <EditorButtons
            tab={tab}
            setTab={setTab}
            onReset={() => resetHandler(lang)}
            openAI={() => setAiModal(lang)}
            showAI={AI_LANGUAGES.includes(lang)}
        />
        {mainContent}
    </ContentBox>;
}

export default React.memo(DeafultContentItem);