import * as React from 'react';

import {PreviewBox} from '../stylings';
import Nl2br from '../Nl2br';

interface IProps {
    text: string;
}

const REPLACER = new RegExp('{{.+}}', 'gm');

function DefaultContentPreview({text}: IProps) {
    const [args, setArgs] = React.useState<{[index: string]:string}>(() => {
        const matches = text.match(REPLACER);
        const out: {[index: string]:string} = {};
        if (!matches) {
            return out;
        }
        for (const match of matches) {
            if (match) {
                out[match.slice(2, -2)] = '';
            }
        }
        return out;
    });

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setArgs(oldArgs => ({
            ...oldArgs,
            [name]: value,
        }));
    }

    const formatted = React.useMemo(() => {
        let out = text;
        for (const [arg, value] of Object.entries(args)) {
            const argRegex = new RegExp(`{{${arg}}}`, 'gm')
            out = out.replace(argRegex, value);
        }
        return out;
    }, [text, args]);

    return <>
        {Object.entries(args).map(([name, value]) => 
            <label key={name}>
                {name}
                <input  name={name} value={value} onChange={onChange} />
            </label>
        )}
        <div>
            <Nl2br text={formatted} />
        </div>
    </>;
}

export default React.memo(DefaultContentPreview);
