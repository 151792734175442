import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {ILanguage, LANGUAGES} from '../types';

type ILanguageState = {
    [key in ILanguage]: boolean;
}

const initialState: ILanguageState = LANGUAGES.reduce((obj, lang) => {
    obj[lang] = true;
    return obj;
}, {} as ILanguageState);

const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        toggleLanguage(state, action: PayloadAction<{lang: ILanguage, to: boolean}>) {
            const {lang, to} = action.payload;
            state[lang] = to;
        }
    },
});

export const { toggleLanguage } = languagesSlice.actions
export default languagesSlice.reducer;