import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {IState, IDispatch, updateIdFilter} from '../state';

interface IProps {
    version: string;
}

function IdFilter({version}: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const value = useSelector((state: IState) => state.filters[version]?.idFilter ?? '');

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        dispatch(updateIdFilter({version, value}));
    }

    return <label>
        ID: 
        <input type='text' value={value} onChange={onChange} />
    </label>
}

export default IdFilter;