import * as React from 'react';

import PlusMinus from '../PlusMinus';
import {FormattingBox, FormattingHeading, TextEx, FormattingBody} from '../stylings'

function DefaultContentFormatting() {
    const [open, setOpen] = React.useState(false);
    const toggle = () => {
        setOpen(oldOpen => !oldOpen);
    };
    return <FormattingBox onClick={toggle}>
        <FormattingHeading><PlusMinus open={open} />Formatting info</FormattingHeading>
        {open && <FormattingBody>
            <p>
                The formatting for system messages uses a simple system where arguments inside double curly braces <TextEx>{'{{}}'}</TextEx> is replaced by a value when the content is sent. For example: If the text was <TextEx>Welcome to {'{{'}webapp_title{'}}'}</TextEx>, and <TextEx>webapp_title</TextEx> was <TextEx>TalentMiles</TextEx>, it would display as <TextEx>Welcome to TalentMiles</TextEx>
            </p>
            <p>
                Clicking the preview button for any translation allows you to fill in values for the arguments and preview what the result looks like.
            </p>
        </FormattingBody>}
    </FormattingBox>;
}

export default React.memo(DefaultContentFormatting);
