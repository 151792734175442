import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ILanguage, LANGUAGES } from '../types';


interface IAiAssistArgs {
    language: ILanguage;
    text: String;
    version: string;
    examples: [String, String][];
}

interface IAiAssistData {
    translations: string[];
}

export const aiApi = createApi({
    reducerPath: 'aiApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
    tagTypes: ['aiTranslation'],
    endpoints: (builder) => ({
        aiAssist: builder.query<IAiAssistData, IAiAssistArgs>({
            query: (body) => ({
                url: `ai-assist`,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 3600,
            providesTags: ['aiTranslation'],
        }),
    }),
});

export const { useAiAssistQuery } = aiApi;
