import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ILanguage } from '../types';
import { IState, docsItemSelector, IDispatch, selectDiffVersion } from '../state';
import DiffText from '../DiffText';

interface IProps {
    version: string;
    id: string;
    lang: ILanguage;
}

function DocsDiff({ id, lang, version }: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const versions = useSelector((state: IState) => state.versions.versions);
    const diffVersion = useSelector((state: IState) => state.diff.diffVersion);
    const text = useSelector((state: IState) => docsItemSelector(state, version, id)[lang]);
    const diffText = useSelector((state: IState) =>
        diffVersion ? docsItemSelector(state, diffVersion, id)[lang] : null,
    );

    const onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        dispatch(selectDiffVersion(value));
    }

    return <>
        <select onChange={onChange} value={diffVersion}>
            <option value='' key=''>Select version</option>
            {versions.filter(v => v !== version).map(v => <option value={v} key={v}>{v}</option>)}
        </select>
        <div>
            {!!diffVersion &&
                <DiffText newText={text} oldText={diffText || ''} oldVersion={diffVersion} newVersion={version} />
            }
        </div>
    </>;
}

export default React.memo(DocsDiff);
