import { ILangData } from './translations';
import { IContent, ILanguage } from '../types';

interface ISearchCache {
    texts: {
        [index: string]: {
            [index: string]: {
                obj: object,
                result: string;
            };
        };
    };
    docs: {
        [index: string]: {
            [index: string]: {
                obj: object,
                result: string;
            };
        };
    };
    defaultContent: {
        [index: string]: {
            [index: string]: {
                obj: object,
                result: string;
            };
        };
    };
}

const searchCache: ISearchCache = {
    texts: {},
    docs: {},
    defaultContent: {},
};

export function getSearchString(
    content: IContent,
    id: string,
    obj: ILangData,
    langs: ILanguage[],
    obj2: Partial<ILangData> | undefined,
): string {
    const langString = langs.join('');
    let entry = searchCache[content][id]?.[langString];
    if (!entry) {
        if (!searchCache[content][id]) {
            searchCache[content][id] = {};
        }
        entry = searchCache[content][id][langString] = { obj: obj2 ?? obj, result: '' };
    }
    if (!entry.result || entry.obj !== (obj2 ?? obj)) {
        entry.result = Object.entries(obj)
            .filter(([lang, val]) => langs.includes(lang as ILanguage))
            .map(([lang, val]) => {
                const out = (obj2 ?? {})[lang as ILanguage] ?? val ?? '';
                return out.toLowerCase();
            }).join(' ');
    }
    return entry.result;
}