import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {
    IState,
    IDispatch,
    toggleModule,
    textModulesSelector,
    docsModulesSelector,
    defaultContentModulesSelector,
} from './state';
import {IContent} from './types';

const Wrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    label {
        margin-top: 0.5rem;
    }
`;

interface IProps {
    content: IContent;
    version: string;
}

function ModuleSelector({content, version}: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const toggledModules = useSelector((state: IState) => state.modules);
    const modules = useSelector((state: IState) => {
        if (content === 'texts') {
            return textModulesSelector(state, version);
        } else if (content === 'docs') {
            return docsModulesSelector(state, version);
        } else if (content === 'defaultContent') {
            return defaultContentModulesSelector(state, version);
        } else {
            return [];
        }
    })

    const toggle = (event: React.FormEvent<HTMLInputElement>) => {
        dispatch(toggleModule({
            module: event.currentTarget.name,
            to: !event.currentTarget.checked,
        }));
    }

    return <fieldset>
        <legend>Select modules to show</legend>
        <Wrapper>
            {modules.map(mod => <label key={mod}>
                <input name={mod} type='checkbox' checked={!toggledModules[mod]} onChange={toggle} />{' '}
                {mod}
            </label>)}
        </Wrapper>
    </fieldset>;
}

export default React.memo(ModuleSelector);
