import { ILanguage } from './types';

async function fetchJson(url: string, settings?: RequestInit): Promise<any> {
    try {
        var response = await fetch(url, settings);
    } catch (err) {
        throw new Error('Network error');
    }
    if (!response.ok) {
        const err = new Error(`${response.statusText}`);
        throw err;
    }
    const json = await response.json();
    return json;
}

async function getJson(url: string): Promise<any> {
    return fetchJson(url);
}

async function postJson(url: string, data: any, signal?: AbortSignal): Promise<any> {
    const postSettings: RequestInit = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        signal,
    };
    return fetchJson(url, postSettings);
}

export async function getVersions(): Promise<string[]> {
    return getJson('/api/versions');
}

interface IDict {
    [index: string]: string;
}

export type IContent = {
    [key in ILanguage]: IDict;
}

export interface IData {
    texts: IContent,
    docs: IContent,
    defaultContent: IContent,
}

export async function getTranslations(version: string): Promise<IData> {
    return getJson(`/api/data/${version}`);
}

interface ISaveData {
    version: string;
    data: IData;
}

export async function saveTranslations(data: ISaveData): Promise<IData> {
    return postJson(`/api/save`, data);
}