import * as React from 'react';
import styled from 'styled-components';

interface IProps extends React.SVGProps<SVGSVGElement> {
    open: boolean;
}

const Svg = styled.svg`
    width: 1.3rem;
    height: 1.3rem;
    line {
        stroke: black;
        stroke-width: 20;
        stroke-linecap: round;
    }
`;

function PlusMinus({open, ...props}: IProps): JSX.Element {
    return <Svg viewBox='0 0 100 100' {...(props as any)}>
        <line x1='10' x2='90' y1='50' y2='50' />
        {!open && (<line y1='10' y2='90' x1='50' x2='50' />)}
    </Svg>;
}

export default React.memo(PlusMinus);
