import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IModulesState = {
    [index: string]: boolean;
}

const initialState: IModulesState = {};

const modulesSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        toggleModule(state, action: PayloadAction<{module: string, to: boolean}>) {
            const {module, to} = action.payload;
            state[module] = to;
        }
    },
});

export const { toggleModule } = modulesSlice.actions
export default modulesSlice.reducer;