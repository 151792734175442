import { configureStore } from '@reduxjs/toolkit';
import { save, load } from "redux-localstorage-simple";

import translationReducer from './translations';
export { loadTranslations, saveTranslations } from './translations';

import { aiApi } from './ai';
export { useAiAssistQuery } from './ai';

import editsReducer from './edits';
export { editContent, clearSaveStatus, resetEdit, resetAllEdits } from './edits';

import versionsReducer from './versions';
export { loadVersions } from './versions';

import languagesReducer from './languages';
export { toggleLanguage } from './languages';

import modulesReducer from './modules';
export { toggleModule } from './modules';

import diffReducer from './diff';
export { selectDiffVersion } from './diff';

import filtersReducer from './filters';
export {
    updateIdFilter,
    updateSearchFilter,
    search,
    clearSearch,
    updateVersionFilter,
    addShowEmpty,
    removeShowEmpty
} from './filters';

export const store = configureStore({
    reducer: {
        versions: versionsReducer,
        translations: translationReducer,
        languages: languagesReducer,
        filters: filtersReducer,
        edits: editsReducer,
        modules: modulesReducer,
        diff: diffReducer,
        [aiApi.reducerPath]: aiApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save({
        states: ['languages', 'edits', 'modules'],
        namespace: 'translator'
    })).concat(aiApi.middleware),
    preloadedState: load({
        states: ['languages', 'edits', 'modules'],
        namespace: 'translator',
    }),
});
export type IState = ReturnType<typeof store.getState>;
export type IDispatch = typeof store.dispatch;

export {
    anyUnsavedChangesSelector,
    defaultContentCountsSelector,
    defaultContentItemSelector,
    defaultContentModulesSelector,
    defaultContentPageSelector,
    docCountsSelector,
    docsItemSelector,
    docsModulesSelector,
    docsPageSelector,
    hasSearchResults,
    loadStatusSelector,
    textCountsSelector,
    textModulesSelector,
    textsItemSelector,
    textsPageSelector,
    unsavedChangesSelector,
} from './selectors';