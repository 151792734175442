import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IState } from './index';
import { IContent, ILanguage, REFERENCE_LANGUAGE } from '../types';
import { getSearchString } from './searchCache';
import { loadStatusSelector } from './selectors';
import { loadTranslations } from './translations';

export const search = createAsyncThunk(
    'filters/search',
    async (version: string, thunkAPI) => {
        let state = thunkAPI.getState() as IState;
        const { idFilter, searchFilter, versionFilter, showEmpty } = state.filters[version]
            ?? { idFilter: '', searchFilter: '', versionFilter: '', showEmpty: [] };

        if (versionFilter) {
            if (loadStatusSelector(state, versionFilter) !== 'fulfilled') {
                await thunkAPI.dispatch((loadTranslations(versionFilter)));
            }
        }

        state = thunkAPI.getState() as IState;
        const translation = state.translations[version];
        const edits = state.edits[version];
        const compareTranslation = versionFilter && loadStatusSelector(state, versionFilter) === 'fulfilled'
            ? state.translations[versionFilter]
            : null;

        const out: { [key in IContent]: string[] } = {
            texts: [],
            docs: [],
            defaultContent: [],
        };
        const trimIdFilter = idFilter.trim();
        const trimSearchFilter = searchFilter.trim().toLowerCase();
        const langs = Object.entries(state.languages)
            .filter(([lang, enabled]) => enabled)
            .map(([lang, enabled]) => lang as ILanguage);
        for (const content of Object.keys(translation.data) as IContent[]) {
            let { ids, dict } = translation.data[content];
            let contentEdits = edits.data[content] ?? {};
            if (trimIdFilter) {
                ids = ids.filter(id => id.includes(trimIdFilter));
            }
            if (trimSearchFilter) {
                ids = ids.filter(id =>
                    getSearchString(content, id, dict[id], langs, contentEdits[id]).includes(trimSearchFilter)
                );
            }
            if (compareTranslation) {
                const compareDict = compareTranslation.data[content]?.dict ?? {};
                // ids = ids.filter(id =>
                //     (dict[id] ?? {})[REFERENCE_LANGUAGE] !== (compareDict[id] ?? {})[REFERENCE_LANGUAGE]
                // );
                ids = ids.filter(id => {
                    if (!!dict[id] && !compareDict[id]) {
                        return true;
                    }
                    const val = dict[id][REFERENCE_LANGUAGE]?.trim();
                    const ref = compareDict[id][REFERENCE_LANGUAGE]?.trim();
                    return val !== ref;
                });
            }
            if (showEmpty.length > 0) {
                console.log(showEmpty);
                ids = ids.filter(id =>
                    Object.entries(contentEdits[id] ?? dict[id]).some(([lang, val]) =>
                        showEmpty.includes(lang as ILanguage) && (!val)
                    )
                );
            }
            out[content] = ids;
        }
        return out;
    }
);

interface IFilterVersionState {
    showEmpty: ILanguage[];
    isSearching: boolean;
    idFilter: string;
    searchFilter: string;
    versionFilter: string;
    searchResults?: {
        texts: string[];
        docs: string[];
        defaultContent: string[];
    };
}

interface IFIltersState {
    [index: string]: IFilterVersionState;
}

function newFilterState(): IFilterVersionState {
    return {
        showEmpty: [],
        isSearching: false,
        idFilter: '',
        searchFilter: '',
        versionFilter: '',
    };
}

const initialState: IFIltersState = {}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        updateIdFilter(state, action: PayloadAction<{ version: string, value: string }>) {
            const { version, value } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].idFilter = value.toLowerCase();
        },
        updateSearchFilter(state, action: PayloadAction<{ version: string, value: string }>) {
            const { version, value } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].searchFilter = value;
        },
        updateVersionFilter(state, action: PayloadAction<{ version: string, value: string }>) {
            const { version, value } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].versionFilter = value;
        },
        clearSearch(state, action: PayloadAction<string>) {
            const version = action.payload;
            state[version] = newFilterState();
        },
        addShowEmpty(state, action: PayloadAction<{ version: string, lang: ILanguage }>) {
            const { lang, version } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].showEmpty.push(lang);
        },
        removeShowEmpty(state, action: PayloadAction<{ version: string, lang: ILanguage }>) {
            const { lang, version } = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].showEmpty = state[version].showEmpty.filter(l => l !== lang);
        },
    },
    extraReducers: builder => {
        builder.addCase(search.pending, (state, action) => {
            const version = action.meta.arg;
            state[version].isSearching = true;
        });
        builder.addCase(search.rejected, (state, action) => {
            const version = action.meta.arg;
            state[version].isSearching = true;
        });
        builder.addCase(search.fulfilled, (state, action) => {
            const version = action.meta.arg;
            const payload = action.payload;
            if (!state[version]) {
                state[version] = newFilterState();
            }
            state[version].searchResults = payload;
            state[version].isSearching = false;
        });
    }
});

export const {
    updateIdFilter,
    updateSearchFilter,
    clearSearch,
    updateVersionFilter,
    addShowEmpty,
    removeShowEmpty,
} = filtersSlice.actions
export default filtersSlice.reducer;