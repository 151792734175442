export type IContent = 'texts' | 'docs' | 'defaultContent';
export type ILanguage = 'en'|'fi'|'sv'|'de'|'es';
export const LANGUAGES: ILanguage[] = ['en', 'fi', 'sv', 'de', 'es'];
export const AI_LANGUAGES: ILanguage[] = ['fi', 'sv', 'de'];
export const REFERENCE_LANGUAGE: ILanguage = 'en';
export const PAGE_SIZE = 30;
export const LANGUAGE_NAMES = {
    en: 'English',
    fi: 'Finnish',
    sv: 'Swedish',
    de: 'German',
    es: 'Spanish',
}