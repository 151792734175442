import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';

import {IState, IDispatch, updateVersionFilter, selectDiffVersion} from '../state';

interface IProps {
    version: string;
}

const Select = styled.select`
    width: 100%;
`;

function ChangeFilter({version}: IProps) {
    const dispatch = useDispatch<IDispatch>();
    const value = useSelector((state: IState) => state.filters[version]?.versionFilter ?? '');
    const versions = useSelector((state: IState) => state.versions.versions).filter(v => v !== version);

    const onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        dispatch(updateVersionFilter({version, value}));
        dispatch(selectDiffVersion(value));
    };

    return <label>
        Show only translations that have changed since version: 
        <Select value={value} onChange={onChange}>
            <option value=''>---</option>
            {versions.map(v => <option key={v} value={v}>{v}</option>)}
        </Select>
    </label>
}

export default ChangeFilter;