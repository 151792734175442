import {HtmlRenderer, Parser} from 'commonmark';
import * as React from 'react';

const parser = new Parser();
const renderer = new HtmlRenderer();

interface IOwnProps {
    content: string|null;
}

type IProps = IOwnProps & React.HTMLProps<HTMLDivElement>;

function replacer(match: string): string {
    const lastChar = match[match.length - 1];
    if (lastChar !== '/') {
        return `<a target="_blank" href="${lastChar}`;
    }
    return match;
}

function RenderMark({content, ...props}: IProps): JSX.Element {
    if (content) {
        let htmlStr = renderer.render(parser.parse(content));
        htmlStr = htmlStr.replace(/<a href=".{1}/g, replacer);
        const html = {
            __html: htmlStr,
        };
        return <div dangerouslySetInnerHTML={html} {...props} />;
    } else {
        return <div {...props} />;
    }
}

export default React.memo(RenderMark);
