import * as React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {IState, textCountsSelector, docCountsSelector, defaultContentCountsSelector} from '../state';
import { IContent } from '../types';

interface IProps {
    version: string;
    content: IContent;
}

const Wrapper = styled.div`
    margin-bottom: 0.5rem;
`;

function Counts({version, content}: IProps) {
    const [currentCount, totalCount] = useSelector((state: IState) => 
        content === 'texts'
        ? textCountsSelector(state, version)
        : content === 'docs'
        ? docCountsSelector(state, version)
        : content === 'defaultContent'
        ? defaultContentCountsSelector(state, version)
        : [0, 0]
    );

    return <Wrapper>
        {currentCount === totalCount
            ? `Showing ${currentCount} items`
            : `Showing ${currentCount} of ${totalCount} items`
        }
    </Wrapper>
}

export default React.memo(Counts);
