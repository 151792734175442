import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {ILanguage, LANGUAGES, LANGUAGE_NAMES} from './types';
import {IState, IDispatch, toggleLanguage} from './state';

const Wrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    label {
        margin-top: 0.5rem;
    }
`;

function LangSelector() {
    const dispatch = useDispatch<IDispatch>();
    const toggledLanguages = useSelector((state: IState) => state.languages);

    const toggle = (event: React.FormEvent<HTMLInputElement>) => {
        dispatch(toggleLanguage({
            lang: event.currentTarget.name as ILanguage,
            to: event.currentTarget.checked,
        }))
    }

    return <fieldset>
        <legend>Select languages to show</legend>
        <Wrapper>
            {LANGUAGES.map(lang => <label key={lang}>
                <input name={lang} type='checkbox' checked={!!toggledLanguages[lang]} onChange={toggle} />{' '}
                {LANGUAGE_NAMES[lang]}
            </label>)}
        </Wrapper>
    </fieldset>;
}

export default LangSelector;