import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { IDispatch, clearSaveStatus } from './state';

interface IProps {
    success: boolean;
    version: string;
}

interface IBanner {
    $success: boolean;
}

const Banner = styled.div<IBanner>`
    position: fixed;
    z-index: 10000;
    background-color: ${({ $success }) => $success ? 'green' : 'red'};
    top: 10px;
    padding: 10px 15px;
    margin-left: calc(50vw - 10%);
    display: flex;
    align-items: center;
    gap: 15px;
    color: white;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
`;

const Close = styled.svg`
    cursor: pointer;
    width: 1rem;
    height: 1rem;
`;

function SaveResultBanner({ success, version }: IProps): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const clearMsg = () => {
        if (!version) {
            return;
        }
        dispatch(clearSaveStatus(version));
    };
    React.useEffect(() => {
        const timer = setTimeout(() => {
            clearMsg();
        }, 30000);
        return () => clearTimeout(timer);
    }, []);
    const resultText = success
        ? 'Changes saved succesfully'
        : 'Failed to save changes';

    return <Banner $success={success}>
        <span>{resultText}</span>
        <Close onClick={clearMsg} viewBox="0 0 10 10">
            <line x1="0" y1="0" x2="10" y2="10" stroke="currentColor" strokeWidth={2} />
            <line x1="10" y1="0" x2="0" y2="10" stroke="currentColor" strokeWidth={2} />
        </Close>
    </Banner>;
}

export default SaveResultBanner;